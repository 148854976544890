$colors: (
    "general": #F16334,
    "sports": #F13460,
    "technology": #0E8CC0,
    "entertainment": #F2BD34,
    "politics": #9467DE,
    "celebrities": #DA15A5,
    "health": #0ECB97,
    "business": #1897E7,
    "science": #5C367F,
    "latest" : #ccc,
    "DIY": #a88668,
    "local": #CDA9AF
);

body {
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 10px;
    }
  
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
  
    &::-webkit-scrollbar-thumb {
        background: #CCC;
    }
  
    &::-webkit-scrollbar-thumb:hover {
        background: #BBB;
    }
}

.app {
    align-items: center;
    font-family: 'Montserrat', sans-serif;
}

.scoop__header-top-divider {
    border-top: 4px solid #ff008a;
    background: rgba(255,255,255,0.95);
}

.scoop__header-bottom-divider {
    border-top: 4px solid #ff008a;
    background: rgba(255,255,255,0.95);

    @media (max-width: 1425px) {
        & {
            display: none;
        }
    }
}

.scoop__footer-divider {
    position: fixed;
    bottom: 40px;
    height: 4px;
    width: 100%;
    z-index: 200;
    background: #ccc;
    transition: all ease-in-out .3s;

    &.expand {
        bottom: 225px;
    }

    @media (max-width: 1425px) {
        & {
            display: none;
        }
    }
}

.scoop__align-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.scoop__align-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.scoop__align-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.header-container {
    position: sticky;
    top: 0;
    z-index: 1000;
}

.header__tagline {
   color: white !important;
}

.header {
    margin: 0;
    background: rgba(255,255,255,0.95);
    background-color: #ff008a;
    color: white;
    padding: 20px 25%;
    height: 120px;

    @media (max-width: 1425px) {
        & {
            padding: 20px 5%;
        }
    }


    &.sticky {
        height: 70px;
    }

    &__logo {
        width: 160px;
        cursor: pointer;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;

        &.sticky {
            width: 100px;
        }
    }

    &__sticky-category {
        margin-left: 5px;
        text-transform: capitalize;
        font-size: 16px;
        font-weight: 700;
    }

    &__tagline {
        color: #1b2023;
        font-size: 16px;
        font-weight: 500;
        margin-top: 5px;
        text-align: center;
        visibility: visible;
        opacity: 1;

        &.hide {
           visibility: hidden;
           opacity: 0;
        }

        @media (max-width: 1000px) {
            & {
                font-size: 15px;
            }
         }
    }

    &__align {
        align-items: center;
    }

    &__search-icon {
        cursor: pointer;
        margin-right: -25px;
        z-index: 200;

        @media (max-width: 1425px) {
           & {
                display: none !important;
            }
        }
    }

    &__search-input {
        font-family: 'Montserrat';
        font-size: 14px;
        width: 200px;
        margin-right: 5px;
        border-radius: 3px;
        padding: 5px 10px 5px 25px;
        visibility: hidden;
        background-color: #e6e6e6;
        border: none;

        &::placeholder {
            color: black;
            font-family: 'Montserrat';
            font-size: 14px;
            font-weight: 500;
        }

        &:focus {
            outline: none;
            box-shadow: none;
        }

        &:hover {
            visibility: visible;
        }
    }

    &__search-icon:hover + &__search-input {
        visibility: visible;
    }

    &__search-input:not(:placeholder-shown) {
        visibility: visible !important;
    }

    &__social {
        margin-left: 50px;
        float: right;
        padding-top: 4px;

        @media (max-width: 1850px) {
            & {
                margin-left: 0;
                display: flex;
                justify-content: center;
                padding: 10px;
                float: none;
            }
        }

        @media (max-width: 1425px) {
            & {
                display: none;
            }
        }
    }

    &__social-logo {
        width: 25px;
        margin-right: 8px;
        cursor: pointer;
    }

    &__social-logo--big {
        width: 30px;
        margin-right: 8px;
        margin-top: -2px;
        cursor: pointer;
    }

    @media (max-width: 1425px) {
        & {
            margin: 0;
        }
     }
}

.cws-button {
    font-size: 14px;
    width: 150px;
    height: 30px;
    border-radius: 20px;
    background-color: white;
    color: #ff008a;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;

    &__bold {
        font-weight: 700;
        margin-right: 5px;
    }

    @media (max-width: 1200px) {
        & {
           display: none;
        }
    }
}

.scoop-menu {
    margin: 0 15%;
    padding: 0 10%;
    background: rgba(255,255,255,.95);
    display: flex;
    justify-content: center;

    &__new {
        text-transform: uppercase;
        font-size: 10px;
        color: #ff008a;
        font-weight: 800;
        margin-left: -8px;

        @media (max-width: 1425px) {
            & {
               display: none;
            }
        }
    }

    @media (max-width: 1600px) {
        & {
           margin: 0 5%;
        }
    }
}

.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    width: auto;
}

.my-masonry-grid_column {
    padding-left: 0px; /* gutter size */
    background-clip: padding-box;
    margin-right: 5px;

    &:last-child {
        margin-right: 0px;
    }
}

.scoop-content {
    margin-bottom: 230px;
}

.scoop-grid {
    margin: 50px 20% 20px;
    padding: 0 5%;

    @media (max-width: 768px) {
        & {
            margin: 10px 0 10px;
        }
    }

    @media (max-width: 1425px) {
        & {
            margin: 20px 0% 20px;
        }
    }

    &__header {
        color: #1b2023;
        font-size: 30px;
        font-weight: 800;
        padding-right: 5px;
        text-transform: capitalize;
    }

    &__date {
        color: #198fa9;
        font-size: 14px;
        font-weight: 400;
        padding-right: 5px;
        margin-right: -17%;

        @media (max-width: 600px) {
            & {
                margin-right: 10px;
                font-size: 12px;
            }
        }
    }

    &__item {
        font-family: 'Montserrat';
        cursor: pointer;
        overflow: hidden;
        border-radius: 5px;
        border: 2px solid white;
        margin: 5px 0 0 0;
        position: relative;
        width: 100%;
        z-index: 0;
    }

    &__item:hover &__options {
        opacity: 0.9;
        visibility: visible;
        box-shadow: inset 0 15px 25px 0 black;
        z-index: 100;
    }

    &__options {
        display: block;
        opacity: 0;
        visibility: hidden;
        padding: 5px;
        /* opacity: .9; */
        transition: all .15s ease-in-out;
        z-index: 1000;
        height: 100%;
        width: 100%;
        position: absolute;
    }

    &__option-icon {
        width: 20px;
        height: 20px;
    }

    &__option-icon.hide {
        visibility: hidden;
    }

    &__actions {
        visibility: hidden;
        opacity: 0;
        transition: all .2s ease-in-out;
        display: inline;
        overflow: hidden;
    }

    &__action {
        display: inline-block;
        margin: 0 0 0 5px;
        width: 20px;
        height: 20px;
        fill: white !important;
    }

    &__option-icon:hover + &__actions,
    &__actions:hover {
        opacity: 0.9;
        visibility: visible;
        height: 65px;
    }

    &__logo {
        max-width: 50px;
        min-width: 40px;
        height: 30px;
        font-family: 'Montserrat';
        font-size: 12px;
        color: white;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        float: right;
        background: #aaa;
        border-radius: 5px;
        padding: 3px;
        position: absolute;
        top: 5px;
        right: 5px;
    }

    &__image-wrapper {
        border-radius: 3px;
        overflow: hidden;
    }

    &__image {
        max-width: 100%;
        transition: all .3s ease-in-out;
        z-index: 10;
        position: relative;
        width: 100%;
    }

    &__item:hover &__image {
        transform: scale(1.1);
    }

    &__info {
        color: #fff;
        font-size: 13px;
        padding: 5px;
        background-color: black;
        opacity: 0;
        visibility: hidden;
        transition: all 0.15s ease-in-out;
        position: absolute;
        bottom: 0;
        z-index: 90;
        font-weight: 600;
        width: 100%;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
    }

    &__item:hover &__info {
        visibility: visible;
        opacity: 0.7;
    }

    &__title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 16px;
    }

    &__category {
        font-size: 9px;
        text-transform: uppercase;
    }

    @each $category, $color in $colors {
        &__category.#{$category} {
            color: $color;
        }
    }
}

.scoop-list-item {
    text-decoration: none;
    font-family: 'Montserrat';
    margin: 15px;
    margin-top: 10px;
    display: flex;
    text-decoration: none;
    color: #000;
    padding-bottom: 10px;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.35);
    cursor: pointer;
    min-height: 80px;
    position: relative;
    transition: all .2s ease-in-out;
    margin-left: 0px;
    &:hover {
        transform: scale(1.05);
        text-decoration: none;
        color: inherit;
    }
    &__image {
        max-width: 385px;
        max-height: 385px;

        @media (max-width: 768px) {
            & {
                max-width: 170px;
            }
        }

    }

    &__left {
        float: left !important;
        margin-right: 10px;
        height: 100%;
    }

     &__right {
        // width: calc(100% - 120px);
        display: inline-table;
        margin-top: 8px;

        @media (max-width: 768px) {
            & {
                margin-top: 0;
            }
        }
     }

     &__title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: bold;
        // font-size: 12px;
        font-size: 18px;
        // line-height: 15px;
        margin-bottom: 0px;

        @media (max-width: 768px) {
            & {
                font-size: 12px;
                max-width: 100%;
            }
        }
     }

     &__date {
         font-size: 15px;
        /* opacity: 0.45; */
        color: #198fa9;
        margin-top: 15px;
        font-family: 'Montserrat';
        font-style: normal;
        line-height: 15px;
        z-index: -999;

        @media (max-width: 768px) {
            & {
                font-size: 10px;
            }
        }
     }


     &__tags {
        margin-top: -10px;
     }
     &__logo {
        max-width: 160px;
        min-width: 40px;
        height: 30px;
        font-family: 'Montserrat';
        font-size: 12px;
        color: white;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        // float: right;
        background: transparentize($color: #198fa9, $amount: 0.5);
        font-weight: bold;
        border-radius: 5px;
        padding: 3px;
        margin: 10px;
        // // position: absolute;
        // top: 5px;
        // right: 5px;

        @media (max-width: 768px) {
            & {
                max-width: 50px;
            }
        }
    }
}

 .share-modal {
    background-color: rgba(0, 0, 0, .6);
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 1000;
    border-radius: 3px;
    // margin: 5px 5px 0 0;
    width: 100%;
    cursor: default;
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    &.show {
        visibility: visible;
    }

    &__title {
        display: block;
        text-align: center;
        margin: -10px 5px 5px;
    }

    &__share-icon {
        margin: 0 0 0 10px;
        width: 25px;
        height: 25px;
        cursor: pointer;
    }

    &__close {
        position: absolute;
        top: 0;
        left: 0;
        fill: #CCC !important;
        margin: 3px 0 0 3px;
        cursor: pointer;
    }
}

.scoop-details {
    margin: 50px 20% 20px;
    padding: 0 5%;

    @media (max-width: 1425px) {
        & {
            margin: 50px 0% 20px;
        }
    }

    &__category {
        color: #1b2023;
        font-size: 30px;
        font-weight: 900;
        padding-right: 5px;
        text-transform: capitalize;
        padding-left: 0px;
    }

    &__date {
        color: #198fa9;
        font-size: 14px;
        font-weight: 400;
        padding-right: 5px;
        margin-left: 8px;

        @media (max-width: 768px) {
            & {
               display: block;
            }
        }
    }

    @each $category, $color in $colors {
        &__#{$category}-line {
            background-color: $color;
            height: 5px;
            width: 100%;
        }
    }
    &__feed {
        margin: 0px
    }

    @media (max-width: 768px) {
        &__vertical-more {
            display: none;
        }
    }
}

.scoop-vertical-more {
    margin-top: 95px;
    margin-left: 10px;
}

.article-details {
    margin-right: 20px;
    width: 100%;

    @media (max-width: 768px) {
        & {
            margin: 10px 0 10px;
        }
    }

    &__title {
        min-height: 70px;
        color: #1b2023;
        font-size: 24px;
        font-weight: 800;
        padding-right: 5px;
        margin-top: 20px;
    }

    &__image-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 10px;
        max-height: 650px;

        @media (max-width: 768px) {
            & {
            //    max-height: 220px;
                max-height: fit-content;
            }
        }
    }

    &__image {
        width: 100%;
        border-radius: 10px;
    }

    &__description {
        color: #1b2023;
        font-size: 16px;
        font-weight: 400;
        margin-top: 5px;
    }

    &__buttons {
        margin-top: 20px;
    }

    &__share {
        color: #198fa9;
        font-weight: 500;
        margin-right: 10px;
        font-size: 15px;
    }

    @each $category, $color in $colors {
        &__more-in-#{$category} {
            font-size: 14px;
            font-weight: 600;
            cursor: pointer;
            color: white;
            background-color: $color;
            width: 170px;
            border-radius: 25px;
            padding: 7px 10px;
            text-align: center;
        }
    }
}

.details-more {
    margin: 25px 60px 20px;

    @media (max-width: 768px) {
        & {
            margin: 10px 0 10px;
        }
    }

    &__title {
        color: #1b2023;
        font-size: 24px;
        font-weight: 800;
        padding-right: 5px;
    }

    &__category {
        text-transform: capitalize;
    }
}

.scoop-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.scoop-not-exist {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
}

.scoop-fetch {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

@media (min-width: 1425px) {
  .scoop-collapsed-menu {
      display: none;
  }
}

.scoop-collapsed-menu--important {
    display: block !important;
    margin-top: -25px;
    margin-right: -25px;
}

.scoop-expand-menu {
    display: inline-block;
    text-transform: capitalize;
    margin-right: 10px;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
    padding-bottom: 5px;
    color: black;
    text-decoration: none !important;
    margin-top: 7px;

    &:hover {
        color: #ff008a;
    }

    @each $category, $color in $colors {
        &.#{$category}-active {
            border-bottom: 4px solid $color;
            margin-bottom: -4px;
        }
    }

    @media (max-width: 1470px) {
        & {
           font-size: 13px;
        }
    }

    @media (max-width: 1425px) {
        & {
            display: none;
        }
    }
}

.scoop-subheader {
    justify-content: flex-start;
    align-items: center;
    display: flex;
    height: 100%;
    margin-left: 50px;
    margin-top: 20px;

    &__feed-icon {
        width: 30px !important;
        height: 30px !important;
        color: #198fa9 !important;
    }

    &__feed-types {
        margin: 0;
     }

     &__feed-type {
        display: flex;
        justify-content: center;
        cursor: pointer;
     }

    &__feed-type.active {
        border-bottom: 1px solid #198fa9;
     }
}

.sc-scroller {
    color: #ffffff;
    position: fixed;
    bottom: 80px;
    right: 50px;
    width: 40px;
    height: 40px;
    background-color: #ff008a;
    border-radius: 100%;
    line-height: 35px;
    cursor: pointer;
    z-index: 500;
    // opacity: 0;
 }

.sc-arrow {
    transform: rotate(90deg) translateY(-8px) translateX(-2px);
}

.scoop-footer {
    transition: all ease-in-out .3s;
    padding: 0 25%;
    margin-bottom: -185px;
    background-color: #ff008a;
    color: white;
    z-index: 100;
    position: fixed;
    bottom: 0;
    width: 60%;
    height: 225px;
    width: 105%;

    &.show {
        margin-bottom: 0px;
    }

    &__content {
        padding: 25px 0px 0px;
    }

    &__center {
        padding: 0px 5% 30px 7%;
        min-height: 134px;
        border-right: 1px solid #aaa;

        @media (max-width: 1600px) {
            & {
                padding: 0 5% 30px 5%;
            }
        }
    }

    &__last {
        border-right: none;
    }

    &__title {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        padding: 15px 0 10px;
    }

    &__link {
        font-size: 13px;
        font-weight: 500;
        cursor: pointer;
        color: white;
        text-decoration: none;

        &:hover {
            color: black;
            text-decoration: none;
        }
    }

    &__social {
        width: 17px;
        margin-right: 8px;
        cursor: pointer;

        @media (max-width: 1800px) {
            & {
                width: 30px;
                margin-right: 10px;
                margin-bottom: 10px
            }
        }
    }

    &__input {
        display: inline-block;
        width: 205px;
        height: 30px;
        border: 1px solid #aaa;
        border-right: none;
        font-size: 13px;

        &:focus {
            outline: none;
        }

        &::placeholder {
            font-size: 13px;
            color: #aaa;
            font-weight: 500;
        }

        @media (max-width: 1900px) {
            & {
                width: 190px;
                border-right: 1px solid #aaa;
            }
        }

        @media (max-width: 1800px) {
            & {
                width: 170px;
            }
        }

        @media (max-width: 1650px) {
            width: 230px;
            height: 30px;
        }
    }

    &__submit {
        font-size: 13px;
        display: inline-block;
        border: 1px solid #aaa;
        padding: 5px 10px;
        text-transform: uppercase;
        font-weight: 600;
        cursor: pointer;
        height: 30px;

        &:active {
            background-color: #BBB;
        }

        @media (max-width: 1650px) {
            & {
                margin-top: 5px;
            }
        }
    }

    &__cws {
        background-color: white;
        cursor: pointer;
    }

    &__copyright {
        font-size: 12px;
        font-weight: 500;
        margin: 10px 0 25px 15px;
        width: 215px;
    }

    @media (max-width: 1425px) {
        & {
            display: none;
        }
    }
}

.footer-preview {
    padding-right: 10px;

    &.hide &__end,
    &.hide .scoop-footer__copyright {
        display: none;
    }

    &.hide &__expand-arrow {
        transform: rotate(-270deg) translateX(-2px);
    }

    &__expand {
        display: flex;
        justify-content: center;
    }

    &__button {
        background-color: #ff008a;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        margin-top: 10px;
    }

    &__expand-arrow {
        transform: rotate(-90deg);
        width: 12px;
        margin-left: 4px;
        margin-top: 3px;
        cursor: pointer;
    }

    &__end {
        display: flex;
        justify-content: flex-end;
    }

    &__link {
        font-size: 13px;
        font-weight: 500;
        margin-top: 10px;
        cursor: pointer;
    }
}

.scoop-legal {
    margin: 50px 20% 250px;
    padding: 0 5%;
    font-weight: 500;
    text-align: justify;

    &--wide {
         margin: 0;
        padding: 0;
    }

    &__iframe {
        width: 101%;;
        position: fixed;
        top: 200px;
        height: calc(100% - 250px);


    }

    @media (max-width: 1425px) {
        & {
            margin: 50px 0% 25px;
        }
    }

    &__title {
        font-weight: 700;
        font-size: 38px;
        margin-bottom: 40px;

        @media (max-width: 1425px) {
            & {
                font-size: 30px;
                text-align: left;
            }
        }
    }

    &__input {
        width: 100%;
    }

    &__submit {
        background-color: #ff008a !important;
        outline: none;
        box-shadow: none;
        border: none;

        &:focus {
            box-shadow: none;
        }

        &:active {
            box-shadow: none !important;
            background-color: #871452 !important;
        }
    }

    &__field {
        &:focus {
            box-shadow: none;
            border: 1px solid #ff008a;
        }
    }

    a {
        color: #ff008a
    }
}

.search-sticky {
    display: unset;
}

.scoop-local {
    margin: 50px 20% 20px;
    padding: 0 5%;

    &__picker-container {
        margin: auto;
        width: 70%;
        margin-top: 50px;
    }

    &__picker-label {
        font-weight: 500;
        text-align: center;
        font-size: 15px;
        margin-bottom: 15px;
    }

    &__select {
        &:focus {
            box-shadow: none !important;
            outline: none !important;
            border: none !important
        }
    }

    &__control {
        border: none !important;
        background-color: #CCC !important;
        border-radius: 0 !important;
        z-index: 100;
    }

    &__indicators {
        margin-right: 10px;
    }

    &__indicator-separator {
        visibility: hidden;
    }

    &__menu-list {
        &::-webkit-scrollbar {
            width: 5px;
            border-radius: 10px;
        }
      
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }
      
        &::-webkit-scrollbar-thumb {
            background: #CCC;
            border-radius: 10px;
        }
      
        &::-webkit-scrollbar-thumb:hover {
            background: #BBB;
        }
    }
}

